import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { useLocales } from '../../hooks/useLocales';

export const WorkflowButtonGroup = ({
  applyTo,
  setApplyTo,
}: {
  applyTo: 'start' | 'end';
  setApplyTo: (applyTo: 'start' | 'end') => void;
}) => {
  const { translate } = useLocales();
  return (
    <Box>
      <Typography variant="caption" sx={{ fontWeight: 700 }} component="div" gutterBottom>
        {translate('glossary.applyTo', { ns: 'shared' })}
      </Typography>
      <ButtonGroup disableElevation>
        <Button onClick={() => setApplyTo('start')} variant={applyTo === 'start' ? 'contained' : 'outlined'} sx={{ px: 2 }}>
          {translate('glossary.start', { ns: 'shared' })}
        </Button>
        <Button onClick={() => setApplyTo('end')} variant={applyTo === 'end' ? 'contained' : 'outlined'} sx={{ px: 2 }}>
          {translate('glossary.end', { ns: 'shared' })}
        </Button>
      </ButtonGroup>
    </Box>
  );
};
