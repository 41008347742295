/* eslint @typescript-eslint/no-explicit-any: 0 */
import { useLocalStorage } from '@diagrid/cloud-ui-shared';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useGetDaprVersions } from 'src/hooks/useGetDaprVersions';
import { useUpdateMutation } from 'src/redux/store';
import { SANDBOX_EMAIL } from 'src/utils/constants';
import {
  applicationsGraphMock,
  applicationsGraphMockDetails,
  applicationsMock,
  applicationsPanelMock,
  applicationSummaryMock,
  appUsageMetricsMock,
  clusterAdvisoriesMock,
  clusterDashboardAdvisories,
  clusterDetailsMock,
  clusterListDetailsMock,
  clusterMetricsMock,
  clusterStatusMock,
  notificationsListMock,
} from './constants/tourMocks';
import { UsersRolesContext } from './UsersRolesContext';

const initialState: (version: string) => ToursState = (version) => ({
  mockClusterId: clusterDetailsMock(version).id,
  completedUITours: [],
  tourType: '',
  isShowClusterStatusMock: false,
  installDaprEnabled: true,
  isTourOpen: false,
  isShowDemoCluster: false,
  isShowAppGraph: false,
  isShowAppGraphIsolate: false,
  isShowAppGraphPanel: false,
  isSecurityAdvisorDetailOpen: false,
  clusterName: clusterListDetailsMock(version).name,
  clusterStatusMock,
  clusterListDetailsMock: clusterListDetailsMock(version),
  clusterDetailsMock: clusterDetailsMock(version),
  clusterMetricsMock,
  clusterAdvisoriesMock,
  clusterDashboardAdvisories,
  applicationsMock: applicationsMock(version),
  applicationsGraphMock,
  applicationsGraphMockDetails,
  applicationsPanelMock: applicationsPanelMock(version),
  applicationSummaryMock: applicationSummaryMock(version),
  appUsageMetricsMock,
  notificationsListMock,
});

const initialContextState: ToursStateContextType = {
  setTourState: () => {},
  resetTourState: () => {},
  submitCompletedUITours: () => {},
  ...initialState(''),
};

const ToursStateContext = createContext(initialContextState);

function ToursStateProvider({ children }: PropsWithChildren) {
  const { sortedDaprVersions } = useGetDaprVersions({ withData: false });
  const latestDaprVersion = sortedDaprVersions[0]?.version;
  const [state, setState] = useState(initialState(latestDaprVersion));
  const { diagridAccount, refetchDiagridAccount, isLoadingDiagridUser, isFetchingDiagridUser } = useContext(UsersRolesContext);
  const [completedToursLocal, setCompletedToursLocal] = useLocalStorage('completedUITours', []);

  const [updateTourSettings] = useUpdateMutation();

  const isSandbox = useMemo(() => diagridAccount?.email === SANDBOX_EMAIL, [diagridAccount?.email]);

  const completedUITours = useMemo(
    () => (isSandbox ? completedToursLocal : diagridAccount?.completedUITours),
    [completedToursLocal, diagridAccount?.completedUITours, isSandbox]
  );
  const isLoading = useMemo(() => isLoadingDiagridUser || isFetchingDiagridUser, [isLoadingDiagridUser, isFetchingDiagridUser]);

  useEffect(() => {
    setState(initialState(latestDaprVersion));
  }, [latestDaprVersion]);

  const submitCompletedUITours = async (type: string) => {
    if (type && completedUITours.includes(type)) return;

    if (isSandbox) {
      // if the user is a sandbox user, we need to store the completed tours in local storage not the backend so we can persist the tours across page reloads but not for _all_ sandbox users
      setCompletedToursLocal([type, ...completedToursLocal]);
      return;
    }

    await updateTourSettings({
      type: 'tours',
      resourceType: 'tours',
      data: {
        completedUITours: [type, ...completedUITours],
      },
    }).unwrap();
    refetchDiagridAccount();
  };

  const setTourState = (state) => {
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const resetTourState = () => {
    setState(initialState(latestDaprVersion));
  };

  return (
    <ToursStateContext.Provider
      value={{
        ...initialContextState,
        ...state,
        setTourState,
        resetTourState,
        completedUITours,
        submitCompletedUITours,
        isLoading,
      }}
    >
      {children}
    </ToursStateContext.Provider>
  );
}

export { ToursStateContext, ToursStateProvider };
export const TOUR_TYPES = {
  DEFAULT_TOUR: '',
  CONNECT_CLUSTER: 'connect-cluster',
  EXPLORE_ADVISORIES: 'explore-advisories',
  EXPLORE_APPLICATIONS: 'explore-applications',
};
export type TourType = (typeof TOUR_TYPES)[keyof typeof TOUR_TYPES];

export type ToursState = {
  mockClusterId?: string;
  completedUITours?: string[];
  tourType?: TourType;
  isTourOpen?: boolean;
  isLoading?: boolean;
  installDaprEnabled?: boolean;
  isShowDemoCluster?: boolean;
  isShowAppGraph?: boolean;
  isShowAppGraphIsolate?: boolean;
  isShowAppGraphPanel?: boolean;
  clusterName?: string;
  isShowClusterStatusMock?: boolean;
  isSecurityAdvisorDetailOpen?: boolean;
  clusterStatusMock?: any;
  clusterListDetailsMock?: any;
  clusterDetailsMock?: any;
  clusterMetricsMock?: any;
  clusterAdvisoriesMock?: any;
  clusterDashboardAdvisories?: any;
  applicationsMock?: any;
  applicationsGraphMock?: any;
  applicationsGraphMockDetails?: any;
  applicationsPanelMock?: any;
  applicationSummaryMock?: any;
  appUsageMetricsMock?: any;
  notificationsListMock?: any;
};

type ToursStateContextType = ToursState & {
  submitCompletedUITours: (string) => void;
  setTourState: (state: ToursState) => void;
  resetTourState: () => void;
};
