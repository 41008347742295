import { usePendo } from '@diagrid/cloud-ui-shared';
import { KeyboardBackspace } from '@mui/icons-material';
import { Box, alpha, useTheme } from '@mui/material';
import { TourProvider, useTour } from '@reactour/tour';
import { PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TOUR_TYPES } from 'src/contexts/ToursStateContext';
import { useToursState } from 'src/hooks/useToursState';
import { TourBadge } from './TourBadge';
import { setAdvisoriesStep, setApplicationsSteps, setClusterStep } from './scenarios';

export function TourProviderWrapper({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const { trackEvent } = usePendo({});
  const theme = useTheme();
  const { setTourState, resetTourState, tourType, submitCompletedUITours, ...tourState } = useToursState();
  const { setCurrentStep } = useTour();
  const [step, setStep] = useState(0);

  // allow this wrapper to manage the step progression for the tour so we can set custom state, navigations, etc.
  const handleSetCurrentStep = (step) => {
    switch (tourType) {
      case TOUR_TYPES.CONNECT_CLUSTER:
        setClusterStep(step, navigate, setStep, setTourState, submitCompletedUITours);
        break;
      case TOUR_TYPES.EXPLORE_ADVISORIES:
        setAdvisoriesStep(step, navigate, setStep, setTourState, submitCompletedUITours);
        break;
      case TOUR_TYPES.EXPLORE_APPLICATIONS:
        setApplicationsSteps(step, navigate, setStep, setTourState, submitCompletedUITours, tourState);
        break;
      default:
        break;
    }
  };
  const onTourClose = ({ setIsOpen }) => {
    trackEvent('Tour_Closed', { type: tourType, step });
    setIsOpen(false);
    setCurrentStep(0);
    setStep(0);
    resetTourState();
    navigate('/clusters', { replace: true });
    document.body.style.overflow = '';
  };

  const nextButton = ({ setCurrentStep, currentStep, stepsLength, setIsOpen }) => {
    const isDisable = currentStep === stepsLength - 1;

    const onFinish = ({ setIsOpen }) => {
      trackEvent('Tour_Finished', { type: tourType });
      onTourClose({ setIsOpen });
    };

    return (
      <Box
        onClick={() => (isDisable ? onFinish({ setIsOpen }) : setCurrentStep(currentStep + 1))}
        sx={{
          color: isDisable ? theme.palette.primary.main : theme.palette.primary.darker,
          lineHeight: 0,
          cursor: 'pointer',
          padding: '0.5rem',
          fontWeight: 700,
          '&:hover': {
            color: theme.palette.primary.dark,
          },
        }}
      >
        {isDisable ? 'Finish' : <KeyboardBackspace sx={{ transform: 'rotate(180deg)' }} />}
      </Box>
    );
  };

  const prevButton = ({ setCurrentStep, currentStep }) => {
    const isDisable = currentStep === 0;
    return (
      <Box
        onClick={() => (isDisable ? null : setCurrentStep(currentStep - 1))}
        sx={{
          color: isDisable ? alpha(theme.palette.primary.darker, 0.5) : theme.palette.primary.darker,
          lineHeight: 0,
          cursor: isDisable ? 'not-allowed' : 'pointer ',
          padding: '0.5rem',
          '&:hover': {
            color: theme.palette.primary.dark,
          },
        }}
      >
        <KeyboardBackspace />
      </Box>
    );
  };

  return (
    <TourProvider
      steps={[]}
      disableDotsNavigation
      disableInteraction
      onClickMask={() => null}
      setCurrentStep={handleSetCurrentStep}
      currentStep={step}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      components={{ Badge: TourBadge } as any}
      keyboardHandler={(e, clickProps) => {
        if (e.key === 'ArrowRight') {
          clickProps.setCurrentStep(Math.min(clickProps.currentStep + 1, clickProps.steps.length - 1));
        }
        if (e.key === 'ArrowLeft') {
          clickProps.setCurrentStep(Math.max(clickProps.currentStep - 1, 0));
        }
      }}
      nextButton={nextButton}
      prevButton={prevButton}
      onClickClose={onTourClose}
      styles={{
        popover: (base) => ({
          ...base,
          borderRadius: '0.5rem',
          maxWidth: '380px',
          boxShadow: '0 0 1rem rgba(0, 0, 0, 0.75)',
        }),
        maskArea: (base) => ({ ...base, rx: 10 }),
      }}
    >
      {children}
    </TourProvider>
  );
}
