import { Box, useTheme } from '@mui/material';
import { useLocales } from '../../hooks/useLocales';

export const WorkflowApplyToIndicator = ({ applyTo }: { applyTo: 'start' | 'end' }) => {
  const theme = useTheme();
  const { translate } = useLocales();
  return (
    <Box
      sx={{
        fontSize: '0.875rem',
        fontWeight: 700,
        textTransform: 'uppercase',
        color: theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.grey[800],
      }}
    >
      {translate(`glossary.${applyTo}`, { ns: 'shared' })}
    </Box>
  );
};
