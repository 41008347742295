import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import { useLocales } from '../../hooks/useLocales';

export const RangeButtonGroup = ({
  onChangeTab,
  currentTab,
}: {
  onChangeTab: (event: ChangeEvent<object> | null, newValue: string) => void;
  currentTab: string;
}) => {
  const { translate } = useLocales();
  return (
    <Box>
      <Typography variant="caption" sx={{ fontWeight: 700 }} component="div" gutterBottom>
        {translate('glossary.rangeType', { ns: 'shared' })}
      </Typography>
      <ButtonGroup disableElevation>
        <Button onClick={() => onChangeTab(null, 'relative')} variant={currentTab === 'relative' ? 'contained' : 'outlined'} sx={{ px: 2 }}>
          {translate('glossary.relativeTab', { ns: 'shared' })}
        </Button>
        <Button onClick={() => onChangeTab(null, 'absolute')} variant={currentTab === 'absolute' ? 'contained' : 'outlined'} sx={{ px: 2 }}>
          {translate('glossary.absoluteTab', { ns: 'shared' })}
        </Button>
      </ButtonGroup>
    </Box>
  );
};
