import { useAuth0 } from '@auth0/auth0-react';
import { CUSTOM_CLAIMS } from '@diagrid/cloud-ui-shared';
import { ProgressBarStyle } from '@diagrid/cloud-ui-shared/components';
import { useUnleashClient } from '@unleash/proxy-client-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NotistackProvider } from './components/NotistackProvider';
import { ScrollToTop } from './components/ScrollToTop';
import { ChartStyle } from './components/chart';
import Router from './routes';
import { security } from './utils';

export default function App() {
  const { getAccessTokenSilently, user, loginWithRedirect, logout, isLoading: isLoadingAuth } = useAuth0();
  const location = useLocation();
  const client = useUnleashClient();

  security.setAccessTokenSilently(getAccessTokenSilently);
  security.setAuth0User(user);
  security.loginWithRedirect = loginWithRedirect;
  security.logout = logout;

  useEffect(() => {
    const clientContext = client.getContext();

    const init = async () => {
      if (user?.email && user?.[CUSTOM_CLAIMS.defaultOrganization]) {
        await client.setContextField('userId', user.email);
        await client.setContextField('userOrgId', user?.[CUSTOM_CLAIMS.defaultOrganization]);
        await client.start();
      }
    };

    if (!isLoadingAuth && user?.email && user?.[CUSTOM_CLAIMS.defaultOrganization] && !clientContext?.userId) {
      init();
    }
  }, [client, isLoadingAuth, user]);

  useEffect(() => {
    if (
      user &&
      !user?.email_verified &&
      location.pathname !== '/verify-email' &&
      location.pathname !== '/sign-up-success' &&
      location.pathname !== '/error'
    ) {
      localStorage.clear();
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}/verify-email`,
          federated: true,
        },
      });
    }
  }, [location.pathname, logout, user]);

  return (
    <NotistackProvider>
      <ProgressBarStyle />
      <ChartStyle />
      <ScrollToTop />
      <Router />
    </NotistackProvider>
  );
}
