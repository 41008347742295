import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

type TabProps = {
  currentTab: string;
  onChangeTab: (event: ChangeEvent<object> | null, newValue: string) => void;
  setCurrentTab: Dispatch<SetStateAction<string>>;
};

const useTabs = (defaultValues = ''): TabProps => {
  const [currentTab, setCurrentTab] = useState(defaultValues);

  return {
    currentTab,
    onChangeTab: (event, newValue) => {
      setCurrentTab(newValue);
    },
    setCurrentTab,
  };
};

export { useTabs };
