import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { Logo } from '../components/Logo';

export const HeaderStyle: React.FC<BoxProps> = styled(Box)(({ theme }) => ({
  lineHeight: 0,
  width: '100%',
  textAlign: 'center',
  padding: theme.spacing(4, 6),
  img: {
    maxWidth: '24rem',
    margin: '0 auto',
  },
}));

export default function LogoOnlyLayout() {
  return (
    <>
      <HeaderStyle component="header">
        <Logo />
      </HeaderStyle>
      <Outlet />
    </>
  );
}
