import { differenceInMinutes, formatRFC3339 } from 'date-fns';
import { GLOBAL_AVAILABLE_TIMESPANS, GlobalAvailableTimespansProps } from './globals';

export function generateStepDateRangeStep({
  start,
  end,
  extendedTimespans,
}: {
  start: Date;
  end: Date;
  extendedTimespans?: Record<string, GlobalAvailableTimespansProps>;
}) {
  const difMin = differenceInMinutes(new Date(end), new Date(start));
  let step = '10s'; // Default step
  const oneHour = 60;
  const oneDay = 24 * 60;

  const allTimespans = { ...GLOBAL_AVAILABLE_TIMESPANS, ...(extendedTimespans ?? {}) };

  // Create a sorted array of timespans (from smallest to largest) for more predictable matching
  const sortedTimespans = Object.entries(allTimespans)
    .map(([key, value]) => ({
      key,
      ...value,
      minuteValue: calculateMinutes(value.ts || '0'),
    }))
    .sort((a, b) => a.minuteValue - b.minuteValue);

  // Loop through sorted timespans to find the appropriate step
  for (const timespan of sortedTimespans) {
    const { ts, step: timespanStep } = timespan;

    if (!ts || !timespanStep) continue;

    // Extract the numeric part and unit
    const match = ts.match(/^(\d+)([mhd])$/);
    if (!match) continue;

    const [, valueStr, unit] = match;
    const value = parseInt(valueStr, 10);

    // Check if the timespan applies based on unit
    let applies = false;
    if (unit === 'm' && difMin >= value) {
      applies = true;
    } else if (unit === 'h' && difMin / oneHour >= value) {
      applies = true;
    } else if (unit === 'd' && difMin / oneDay >= value) {
      applies = true;
    }

    // Update step if this timespan applies
    if (applies) {
      step = timespanStep;
    }
  }

  return {
    start: formatRFC3339(new Date(start)),
    end: formatRFC3339(new Date(end)),
    step,
  };
}

// Helper to convert timespan string to minutes for sorting
function calculateMinutes(ts: string): number {
  const match = ts.match(/^(\d+)([mhd])$/);
  if (!match) return 0;

  const [, valueStr, unit] = match;
  const value = parseInt(valueStr, 10);

  if (unit === 'm') return value;
  if (unit === 'h') return value * 60;
  if (unit === 'd') return value * 24 * 60;

  return 0;
}
