import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { useLocalStorage, useWindowFocus } from '@diagrid/cloud-ui-shared/hooks';
import { UserOrganization } from '@diagrid/cloud-ui-shared/types';
import { AxiosInstance } from 'axios';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { PropsWithChildren, createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { CUSTOM_CLAIMS, extractErrorMessage, handleError } from '../utils';

export type OrganizationContextStateProps = {
  allOrganizations: UserOrganization[];
  userDefaultOrgId: string | null;
  productOrganizations: UserOrganization[];
  organization: UserOrganization | Record<string, never>;
  loading: boolean;
  planName: string | null;
  cleanPlanName: string | null;
  setDefaultOrg: (orgId: string, toRoute?: string) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchOrganizations: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchDefaultOrganization: any;
};

type OrganizationContextProps = {
  currentProduct: 'cra' | 'mcp'; // catalyst or conductor
  auth0Apis: Record<string, string>;
  jsonApiClient: AxiosInstance;
  paths: {
    userOrganizations: string;
  };
  translations: {
    fallbackError: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useFindQuery: any;
};

const initialState: OrganizationContextStateProps = {
  allOrganizations: [],
  userDefaultOrgId: null,
  productOrganizations: [],
  organization: {},
  loading: false,
  planName: null,
  cleanPlanName: null,
  setDefaultOrg: (_orgId: string, _toRoute?: string) => Promise.resolve(),
  refetchOrganizations: (_?: unknown) => Promise.resolve(),
  refetchDefaultOrganization: (_?: unknown) => Promise.resolve(),
};

const OrganizationContext = createContext({ ...initialState });

function OrganizationProvider({
  currentProduct,
  auth0Apis,
  jsonApiClient,
  paths,
  translations,
  useFindQuery,
  children,
}: OrganizationContextProps & PropsWithChildren) {
  const navigate = useNavigate();
  const { isFocused } = useWindowFocus();
  const { enqueueSnackbar } = useSnackbar();
  const { user: auth0User, isLoading: isLoadingAuth, logout } = useAuth0();

  const [isSettingDefaultOrg, setIsSettingDefaultOrg] = useState(false);
  const [state, setState] = useState(initialState);
  const [activeProduct] = useState(currentProduct);
  const [inactiveProduct] = useState(currentProduct === 'mcp' ? 'cra' : 'mcp');

  const [cachedOrgId, setCachedOrgId] = useLocalStorage<string>(
    `${auth0User?.sub}/currentOrg`,
    auth0User?.[CUSTOM_CLAIMS.defaultOrganization]
  );

  const {
    data: diagridUser,
    refetch: refetchDiagridUser,
    isLoading: isLoadingDiagridUser,
    isFetching: isFetchingDiagridUser,
  } = useFindQuery({ type: 'userSelf', normalization: { skip: true } }, { skip: isLoadingAuth || !auth0User?.sub });

  // all the orgs the user is part of (excludes org data like limits and usage)
  const {
    data: userOrganizations,
    isLoading: isLoadingUserOrgs,
    refetch: refetchUserOrganizations,
    isFetching: isFetchingUserOrgs,
  } = useFindQuery(
    {
      type: 'userOrganizations',
      normalization: { raw: true },
    },
    { skip: isLoadingAuth || !auth0User?.sub }
  );

  // the default org the user is part of (includes limits and usages, etc)
  const {
    data: defaultOrg,
    refetch: refetchDefaultOrg,
    isLoading: isLoadingDefaultOrg,
  } = useFindQuery(
    {
      type: 'organizations',
      normalization: { raw: true },
    },
    { skip: isLoadingAuth || !auth0User?.sub }
  );

  const diagridUserDefaultOrgId = diagridUser?.data?.user_metadata?.defaultOrg;

  const isLoading =
    isLoadingAuth ||
    isLoadingDiagridUser ||
    isLoadingUserOrgs ||
    isFetchingDiagridUser ||
    isFetchingUserOrgs ||
    isSettingDefaultOrg ||
    isLoadingDefaultOrg;

  const setDefaultOrg = useCallback(
    async (orgId: string, toRoute?: string) => {
      try {
        setIsSettingDefaultOrg(true);
        setCachedOrgId(orgId);
        await jsonApiClient.put(`${paths.userOrganizations}/${orgId}`);
        const { data: userResp, error } = await refetchDiagridUser();

        const neuDefaultOrgId = userResp?.data?.user_metadata?.defaultOrg;

        if (neuDefaultOrgId !== orgId || error) {
          setCachedOrgId(neuDefaultOrgId);
          setIsSettingDefaultOrg(false);
          throw new Error('Failed to set default org', { cause: error });
        }

        setState((prevState) => ({
          ...prevState,
          userDefaultOrgId: userResp?.data?.user_metadata?.defaultOrg,
        }));

        if (toRoute) {
          window.location.replace(`${window.location.origin}${toRoute}`);
        } else {
          window.location.reload();
        }
      } catch (err) {
        setIsSettingDefaultOrg(false);
        console.error(err);
        handleError({
          error: err,
          enqueueSnackbar,
          overrideFallbackErrorMessage: translations.fallbackError,
        });
      }
    },
    [enqueueSnackbar, jsonApiClient, paths.userOrganizations, refetchDiagridUser, setCachedOrgId, translations.fallbackError]
  );

  // set the user default org id
  useEffect(() => {
    if (diagridUserDefaultOrgId !== state.userDefaultOrgId && window.Cypress) {
      setState((prevState) => ({ ...prevState, userDefaultOrgId: diagridUserDefaultOrgId }));
    }
  }, [diagridUserDefaultOrgId, state.userDefaultOrgId]);

  // setup org data
  useEffect(() => {
    if (userOrganizations?.data?.length > 0) {
      setState((prevState) => ({
        ...prevState,
        allOrganizations: userOrganizations.data,
        productOrganizations: userOrganizations.data
          .filter((o) => o?.products?.[activeProduct]?.enabled)
          .sort((a, b) => a.name.localeCompare(b.name)),
      }));
    }
  }, [activeProduct, userOrganizations?.data]);

  // set plan name
  useEffect(() => {
    if (!isEmpty(defaultOrg?.data?.products)) {
      const product = defaultOrg?.data?.products[currentProduct];
      setState((prevState) => ({
        ...prevState,
        planName: product?.plan,
        cleanPlanName: product?.plan?.replace(currentProduct === 'cra' ? 'cra:' : 'mcp:', ''),
        organization: defaultOrg?.data,
      }));
    }
    if (!isEmpty(defaultOrg?.data?.name)) {
      datadogRum.setUserProperty('organizationName', defaultOrg?.data?.name);
    }
  }, [currentProduct, defaultOrg?.data]);

  // Loading org
  useEffect(() => {
    if (
      (isLoadingAuth || isLoadingDiagridUser || isLoadingUserOrgs || isFetchingDiagridUser || isSettingDefaultOrg) &&
      state.loading === false
    ) {
      setState((prevState) => ({ ...prevState, loading: true }));
    } else if (
      !isLoadingAuth &&
      !isLoadingDiagridUser &&
      !isLoadingUserOrgs &&
      !isFetchingDiagridUser &&
      !isSettingDefaultOrg &&
      state.loading === true
    ) {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  }, [isLoadingAuth, isLoadingDiagridUser, isLoadingUserOrgs, isFetchingDiagridUser, state.loading, isSettingDefaultOrg]);

  const isValidatingOrg = useRef(false);

  useEffect(() => {
    async function validateOrg() {
      if (isValidatingOrg.current) return; // If already validating, return early

      isValidatingOrg.current = true; // Set flag

      const currentDefaultOrg = state.allOrganizations?.find((o) => o.id === diagridUserDefaultOrgId);
      const isDefaultOrgInactiveProduct =
        (currentDefaultOrg?.products?.[inactiveProduct]?.enabled && !currentDefaultOrg?.products?.[activeProduct]?.enabled) ?? false;

      if (diagridUserDefaultOrgId !== cachedOrgId && !window.Cypress) {
        try {
          if (!state.productOrganizations?.find((o) => o.id === diagridUserDefaultOrgId) || isDefaultOrgInactiveProduct) {
            if (state.productOrganizations.length > 0) {
              setIsSettingDefaultOrg(true);
              await setDefaultOrg(state.productOrganizations[0].id);
            } else {
              const { error: userError } = await refetchDiagridUser();
              const { data: refetchedUserOrgs, error: orgsError } = await refetchUserOrganizations();

              if (userError || orgsError) {
                console.error('Failed to refetch user or orgs', { userError, orgsError });
                let errDesc = 'Error reading user organizations';
                if (userError) {
                  errDesc = `${errDesc}, ${extractErrorMessage(userError)}`;
                }
                if (orgsError) {
                  const orgErrMsg = extractErrorMessage(orgsError);
                  if (!userError || orgErrMsg !== extractErrorMessage(userError)) {
                    errDesc = `${errDesc}, ${orgErrMsg}`;
                  }
                }
                logout({
                  clientId: auth0Apis.clientId,
                  logoutParams: {
                    returnTo: `${window.location.origin}/error?error=${encodeURIComponent(
                      'access_denied'
                    )}&error_description=${encodeURIComponent(errDesc)}`,
                    federated: true,
                  },
                });
              }

              if (refetchedUserOrgs?.data?.length > 0) {
                setState((prevState) => ({
                  ...prevState,
                  allOrganizations: refetchedUserOrgs.data,
                  productOrganizations: refetchedUserOrgs.data.filter((o) => o?.products?.[activeProduct]?.enabled),
                }));
              }

              validateOrg();
            }
          } else if (cachedOrgId !== diagridUserDefaultOrgId) {
            setIsSettingDefaultOrg(true);
            await setDefaultOrg(diagridUserDefaultOrgId ?? state.productOrganizations[0].id);
          }
        } catch (error) {
          console.error(error);
        }
      }

      isValidatingOrg.current = false; // Reset flag
    }

    if (!isLoading && isFocused) {
      validateOrg();
    }
  }, [
    activeProduct,
    auth0Apis.clientId,
    cachedOrgId,
    diagridUserDefaultOrgId,
    inactiveProduct,
    isFocused,
    isLoading,
    logout,
    navigate,
    refetchDiagridUser,
    refetchUserOrganizations,
    setDefaultOrg,
    state.allOrganizations,
    state.productOrganizations,
  ]);

  return (
    <OrganizationContext.Provider
      value={{
        ...state,
        setDefaultOrg,
        refetchOrganizations: refetchUserOrganizations,
        refetchDefaultOrganization: refetchDefaultOrg,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
}

export { OrganizationContext, OrganizationProvider };
