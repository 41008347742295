import { TabContext, TabPanel } from '@mui/lab';
import { Box, Button, FormControlLabel, InputAdornment, Popover, Radio, RadioGroup, TextField, Typography, useTheme } from '@mui/material';
import { LocalizationProvider, DateTimeRangePicker as MuiDateTimeRangePicker, CalendarIcon } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { addMinutes, differenceInHours, format } from 'date-fns';
import { ChangeEvent, memo, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DISPLAY_DATETIME_FORMAT } from '../../utils';
import { useTabs } from '../../hooks';
import { useLocales } from '../../hooks/useLocales';
import { RangeButtonGroup } from './RangeButtonGroup';
import { WorkflowApplyToIndicator } from './WorkflowApplyToIndicator';
import { WorkflowButtonGroup } from './WorkflowButtonGroup';
const resetToDefault = { relativeTime: undefined, startTime: undefined, endTime: undefined, dateApplied: undefined };

export type DateTimeRangePickerProps = {
  label?: string;
  value?: DateTimeRangePickerValues;
  defaultValue?: DateTimeRangePickerValues;
  onChange?: (x: DateTimeRangePickerValues | null) => void;
  minDate?: Date;
  // In some situations you don't want the exact min date, but a date that is offset by a few minutes
  // This is in case backend accept exact date and by the time api reaches,
  // the time is already passed the allowed date by backend
  minDateOffsetMinutes?: number;
  maxDate?: Date;
  disableFuture?: boolean;
  relativeOptions: { value: string; label: string }[];
  workflowFilterMode?: boolean;
};

type ApplyTo = 'start' | 'end' | null;

export type DateTimeRangePickerValues = {
  startTime?: Date;
  endTime?: Date;
  relativeTime?: string;
  dateApplied?: Date;
  applyTo?: ApplyTo;
};

function DateTimeRangePickerBase({
  onChange,
  value,
  defaultValue,
  label,
  minDate,
  minDateOffsetMinutes = 0,
  maxDate,
  disableFuture = false,
  relativeOptions = [],
  workflowFilterMode = false,
}: DateTimeRangePickerProps) {
  const { translate } = useLocales();
  const theme = useTheme();
  const { currentTab, onChangeTab } = useTabs('relative');
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLDivElement) | null>(null);
  // Store dates as actual Date objects, not strings
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [relativeTime, setRelativeTime] = useState('');
  const [presentString, setPresentString] = useState('');
  const [applyTo, setApplyTo] = useState<ApplyTo>(workflowFilterMode ? 'start' : null);
  const [error, setError] = useState('');
  // Add a ref to track if the picker is being opened
  const isOpeningPickerRef = useRef(false);
  // Store previous value to prevent unnecessary updates
  const prevValueRef = useRef<DateTimeRangePickerValues | undefined>();

  // Create stable versions of the value and defaultValue props
  const stableValue = useMemo(() => createStableDateValue(value, workflowFilterMode), [value, workflowFilterMode]);

  const stableDefaultValue = useMemo(() => createStableDateValue(defaultValue), [defaultValue]);

  // Memoize the check for empty values to ensure consistency
  const isValueEmpty = useCallback((val?: DateTimeRangePickerValues) => {
    return !val || (val.relativeTime === undefined && val.startTime === undefined && val.endTime === undefined);
  }, []);

  const handlePresentString = useCallback(
    ({ relativeTime, endDate, startDate }: { isRelative?: boolean; endDate?: string; startDate?: string; relativeTime?: string }) => {
      if (relativeTime) {
        const getLabel = relativeOptions.find((option) => option.value === relativeTime)?.label ?? '';
        setPresentString(getLabel);
      } else {
        if (!startDate || !endDate) return;
        setPresentString(`${startDate} - ${endDate}`);
      }
    },
    [relativeOptions]
  );

  // Update local state when prop value changes
  useEffect(() => {
    // Skip state updates if we're currently opening the picker
    if (isOpeningPickerRef.current) {
      return;
    }

    // Skip if the value hasn't changed (deep comparison)
    if (
      prevValueRef.current?.startTime?.getTime() === stableValue?.startTime?.getTime() &&
      prevValueRef.current?.endTime?.getTime() === stableValue?.endTime?.getTime() &&
      prevValueRef.current?.relativeTime === stableValue?.relativeTime &&
      prevValueRef.current?.applyTo === stableValue?.applyTo
    ) {
      return;
    }

    // Update the previous value ref
    prevValueRef.current = stableValue;

    const isEmpty = isValueEmpty(stableValue);
    const getValue = isEmpty ? stableDefaultValue : stableValue;

    // Reset state to avoid any stale values
    if (isEmpty) {
      if (stableDefaultValue) {
        // If there's a default value, use it
        setRelativeTime(stableDefaultValue.relativeTime || '');

        if (stableDefaultValue.startTime && stableDefaultValue.endTime) {
          setDateRange([new Date(stableDefaultValue.startTime), new Date(stableDefaultValue.endTime)]);

          // Format dates for display
          const startFormatted = format(new Date(stableDefaultValue.startTime), DISPLAY_DATETIME_FORMAT);
          const endFormatted = format(new Date(stableDefaultValue.endTime), DISPLAY_DATETIME_FORMAT);
          handlePresentString({ startDate: startFormatted, endDate: endFormatted });
        } else if (stableDefaultValue.relativeTime) {
          handlePresentString({ relativeTime: stableDefaultValue.relativeTime });
          setDateRange([null, null]);
        } else {
          setDateRange([null, null]);
          setPresentString('');
        }

        onChange &&
          onChange({
            ...resetToDefault,
            ...stableDefaultValue,
          });
      } else {
        // No default, just reset everything
        setDateRange([null, null]);
        setRelativeTime('');
        setPresentString('');
        onChange && onChange(resetToDefault);
      }
      return;
    }
    if (stableValue?.applyTo) {
      setApplyTo(stableValue?.applyTo);
    }
    // Handle non-empty values
    if (getValue?.startTime && getValue?.endTime) {
      // Check if dates are still valid based on dateApplied
      if (getValue?.dateApplied) {
        const now = new Date();
        const difference = differenceInHours(now, new Date(getValue?.dateApplied));

        if (difference >= 10) {
          setDateRange([null, null]);
          onChange && onChange({ ...resetToDefault, ...(stableDefaultValue || {}) });
          return;
        }
      }

      // Store actual Date objects
      const startDate = new Date(getValue.startTime);
      const endDate = new Date(getValue.endTime);

      setDateRange([startDate, endDate]);
      setRelativeTime('');

      // Format dates for display only
      const startFormatted = format(startDate, DISPLAY_DATETIME_FORMAT);
      const endFormatted = format(endDate, DISPLAY_DATETIME_FORMAT);
      handlePresentString({ startDate: startFormatted, endDate: endFormatted });
      return;
    }

    if (getValue?.relativeTime) {
      handlePresentString({ relativeTime: getValue?.relativeTime });
      setRelativeTime(getValue?.relativeTime);
      setDateRange([null, null]);
      return;
    }

    // Default case - only reset if necessary
    // We don't reset dateRange here, to prevent it from interfering with the picker
    setPresentString('');
    setRelativeTime('');

    // Clean up function to prevent stale updates
    return () => {
      // This prevents any pending state updates from affecting unmounted component
    };
  }, [stableValue, stableDefaultValue, onChange, handlePresentString, isValueEmpty, dateRange]);

  const handleClose = useCallback(() => {
    setError('');
    setAnchorEl(null);
    isOpeningPickerRef.current = false;
    setTimeout(() => {
      (document?.activeElement as HTMLElement).blur();
    }, 0);
  }, []);

  const handleDateTimeRangePickerValues = useCallback((newValue: [Date | null, Date | null]) => {
    // Store the Date objects directly without conversion to strings
    setDateRange(newValue);
    setError('');
  }, []);

  const handleRelativeTime = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setRelativeTime(event.target.value);
  }, []);

  const handleApply = useCallback(() => {
    if (currentTab === 'relative') {
      handlePresentString({ relativeTime });

      onChange &&
        onChange({
          relativeTime,
          applyTo,
          startTime: undefined,
          endTime: undefined,
          dateApplied: undefined,
        });
    } else {
      const [startDate, endDate] = dateRange;

      // Check that start date is before end date
      if (startDate && endDate && startDate > endDate) {
        setError(translate('components.dateTimeRangeFilter.error', { ns: 'shared' }));
        return;
      }

      if (startDate && endDate) {
        const now = new Date();
        const isInFuture = endDate > now;
        const isPastMinDate = minDate ? startDate < minDate : false;

        // Create final date objects (with adjustments if needed)
        const finalEndDate = isInFuture ? now : endDate;
        const finalStartDate = isPastMinDate && minDate ? addMinutes(new Date(minDate), minDateOffsetMinutes) : startDate;

        // Update the dateRange if adjustments were made
        if (isInFuture || isPastMinDate) {
          setDateRange([finalStartDate, finalEndDate]);
        }

        // Format dates for display
        const formattedEndDate = format(finalEndDate, DISPLAY_DATETIME_FORMAT);
        const formattedStartDate = format(finalStartDate, DISPLAY_DATETIME_FORMAT);

        handlePresentString({
          endDate: formattedEndDate,
          startDate: formattedStartDate,
        });

        if (onChange) {
          onChange({
            relativeTime: undefined,
            startTime: finalStartDate,
            endTime: finalEndDate,
            dateApplied: now,
            applyTo,
          });
        }
      }
    }
    isOpeningPickerRef.current = false;
    handleClose();
  }, [currentTab, dateRange, relativeTime, handlePresentString, onChange, handleClose, translate, minDate, minDateOffsetMinutes, applyTo]);

  const handleClear = useCallback(() => {
    setError('');
    setDateRange([null, null]);
    setRelativeTime('');

    const val = stableDefaultValue
      ? {
          startTime: undefined,
          endTime: undefined,
          relativeTime: undefined,
          ...stableDefaultValue,
        }
      : { startTime: undefined, endTime: undefined, relativeTime: undefined };
    onChange && onChange(val);
    isOpeningPickerRef.current = false;
    handleClose();
  }, [stableDefaultValue, onChange, handleClose]);

  const handleOpenCorrectTab = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      // Set the flag to prevent other effects from changing state
      isOpeningPickerRef.current = true;

      setAnchorEl(event.currentTarget);

      // Use the consistent empty check
      const isEmpty = isValueEmpty(stableValue);

      // Preserve the current date range when opening - this is crucial!
      if (stableValue?.startTime && stableValue?.endTime) {
        setDateRange([new Date(stableValue.startTime), new Date(stableValue.endTime)]);
      }

      if (stableValue?.relativeTime || isEmpty) {
        onChangeTab(event, 'relative');
      } else {
        onChangeTab(event, 'absolute');
      }

      // Use a short timeout to ensure state updates complete before other effects
      setTimeout(() => {
        isOpeningPickerRef.current = false;
      }, 50);
    },
    [isValueEmpty, onChangeTab, stableValue]
  );

  const open = Boolean(anchorEl);
  const id = open ? 'project-select-popover' : undefined;

  const isEmptyStyles = useMemo(
    () =>
      !presentString
        ? {
            color: theme.palette.grey[500],
            fontStyle: 'italic',
          }
        : {},
    [presentString, theme.palette.grey]
  );

  const getSplitOptionsContent = useCallback(() => {
    const totalOptions = relativeOptions.length;
    if (totalOptions < 5) {
      return <RelativeOptionsGroup options={relativeOptions} value={relativeTime} onChange={handleRelativeTime} />;
    } else {
      const half = Math.ceil(totalOptions / 2);
      const firstHalf = relativeOptions.slice(0, half);
      const secondHalf = relativeOptions.slice(half, totalOptions);
      return (
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '1rem' }}>
          <RelativeOptionsGroup options={firstHalf} value={relativeTime} onChange={handleRelativeTime} />
          <RelativeOptionsGroup options={secondHalf} value={relativeTime} onChange={handleRelativeTime} />
        </Box>
      );
    }
  }, [relativeOptions, relativeTime, handleRelativeTime]);

  // Extra protection: if the picker is open and we have dates, make sure they're preserved
  useEffect(() => {
    if (open && stableValue?.startTime && stableValue?.endTime) {
      setDateRange([new Date(stableValue.startTime), new Date(stableValue.endTime)]);
    }
  }, [open, stableValue]);

  const computedPresentString = useMemo(() => {
    if (presentString) return presentString;
    return translate('dateTimeRangeFilter.mainPlaceholder', { ns: 'shared' });
  }, [presentString, translate]);

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <TextField
        sx={{
          width: workflowFilterMode ? '29.5rem' : '27rem',
          '& input, .MuiInputBase-root': {
            cursor: 'pointer !important',
            ...isEmptyStyles,
          },
        }}
        value={computedPresentString}
        size="small"
        onClick={handleOpenCorrectTab}
        label={label}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <CalendarIcon />
                  {value?.applyTo && presentString && <WorkflowApplyToIndicator applyTo={value?.applyTo} />}
                </Box>
              </InputAdornment>
            ),
          },
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          mt: '3px',
          minWidth: '32rem',
        }}
        // When the popover opens, ensure our dates are preserved
        TransitionProps={{
          onEnter: () => {
            if (stableValue?.startTime && stableValue?.endTime) {
              setDateRange([new Date(stableValue.startTime), new Date(stableValue.endTime)]);
            }
          },
        }}
      >
        <Box
          sx={{
            padding: '1rem',
            minWidth: '15rem',
          }}
        >
          <TabContext value={currentTab}>
            <Box
              sx={{
                mb: 2,
                display: 'grid',
                gridGap: '2.3rem',
                gridTemplateColumns: '1fr 1fr',
              }}
            >
              <RangeButtonGroup onChangeTab={onChangeTab} currentTab={currentTab} />
              {workflowFilterMode && applyTo && <WorkflowButtonGroup applyTo={applyTo} setApplyTo={setApplyTo} />}
            </Box>
            <TabPanel value="relative">{getSplitOptionsContent()}</TabPanel>

            <TabPanel value="absolute">
              <Box sx={{ display: 'flex', gridGap: '1rem', flexWrap: 'wrap', mt: 4 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MuiDateTimeRangePicker
                    localeText={{
                      start: translate('glossary.startFrom', { ns: 'shared' }),
                      end: translate('glossary.endAt', { ns: 'shared' }),
                    }}
                    disableFuture={disableFuture}
                    minDateTime={minDate}
                    maxDateTime={maxDate}
                    value={dateRange}
                    onChange={handleDateTimeRangePickerValues}
                    format={DISPLAY_DATETIME_FORMAT}
                    slotProps={{
                      popper: {
                        sx: {
                          '& .MuiList-root': {
                            '&::-webkit-scrollbar': {
                              width: '0.6rem',
                            },
                            '&::-webkit-scrollbar-track': {
                              background: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
                              borderRadius: '0.5rem',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[600],
                              borderRadius: '0.5rem',
                            },
                          },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </TabPanel>
          </TabContext>
          {error && (
            <Box>
              <Typography
                sx={{
                  background: theme.palette.error.main,
                  padding: '0.5rem 1rem',
                  fontWeight: 700,
                  fontSize: '0.875rem',
                  borderRadius: `${theme.shape.borderRadius}px`,
                  mt: '1rem',
                }}
              >
                {error}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '1rem',
              minWidth: '22rem',
            }}
          >
            <Button onClick={handleClear}>{translate('glossary.clear', { ns: 'shared' })}</Button>
            <Box
              sx={{
                display: 'flex',
                gridGap: '1rem',
              }}
            >
              <Button onClick={handleClose}>{translate('glossary.close', { ns: 'shared' })}</Button>
              <Button variant="contained" onClick={handleApply} disabled={Boolean(error)}>
                {translate('glossary.apply', { ns: 'shared' })}
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

// Define a function to create stable copies of date values to prevent unnecessary rerenders
function createStableDateValue(value?: DateTimeRangePickerValues, workflowFilterMode?: boolean): DateTimeRangePickerValues | undefined {
  if (!value) return undefined;

  return {
    startTime: value.startTime ? new Date(value.startTime) : undefined,
    endTime: value.endTime ? new Date(value.endTime) : undefined,
    relativeTime: value.relativeTime,
    dateApplied: value.dateApplied ? new Date(value.dateApplied) : undefined,
    applyTo: workflowFilterMode ? value.applyTo : null,
  };
}

// Define the props type for RelativeOptionsGroup
type RelativeOptionsGroupProps = {
  options: { value: string; label: string }[];
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

// Memoized for better performance
const RelativeOptionsGroup = memo(({ options, value, onChange }: RelativeOptionsGroupProps) => {
  return (
    <RadioGroup onChange={onChange} value={value}>
      {options.map(({ value, label }) => (
        <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
      ))}
    </RadioGroup>
  );
});

// Wrap the entire component in React.memo with a custom equality function
export const DateTimeRangePicker = memo(DateTimeRangePickerBase, (prevProps, nextProps) => {
  // Check for significant prop changes
  const prevStartTime =
    typeof prevProps.value?.startTime === 'string' ? new Date(prevProps.value?.startTime) : prevProps.value?.startTime?.getTime();
  const nextStartTime =
    typeof nextProps.value?.startTime === 'string' ? new Date(nextProps.value?.startTime) : nextProps.value?.startTime?.getTime();
  const prevEndTime =
    typeof prevProps.value?.endTime === 'string' ? new Date(prevProps.value?.endTime) : prevProps.value?.endTime?.getTime();
  const nextEndTime =
    typeof nextProps.value?.endTime === 'string' ? new Date(nextProps.value?.endTime) : nextProps.value?.endTime?.getTime();

  const datesEqual = prevStartTime === nextStartTime && prevEndTime === nextEndTime;

  const relativeEqual = prevProps.value?.relativeTime === nextProps.value?.relativeTime;
  const applyToEqual = prevProps.value?.applyTo === nextProps.value?.applyTo;

  const optionsEqual =
    prevProps.relativeOptions === nextProps.relativeOptions ||
    (prevProps.relativeOptions.length === nextProps.relativeOptions.length &&
      JSON.stringify(prevProps.relativeOptions) === JSON.stringify(nextProps.relativeOptions));

  // Only re-render if something important changed
  return datesEqual && relativeEqual && optionsEqual && applyToEqual;
});
