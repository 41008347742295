import { toZonedTime, fromZonedTime, formatInTimeZone } from 'date-fns-tz';
import { DISPLAY_DATETIME_FORMAT, isValidDate } from '@diagrid/cloud-ui-shared/utils';

export function getUTCFromLocalDate(date: Date | string): Date | null {
  if (!date) return null;
  try {
    return toZonedTime(date, 'UTC');
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function getFormattedDateFromUTC(date: Date | string, format = DISPLAY_DATETIME_FORMAT): string {
  if (!date) return '';

  date = stripTime(date);

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  let dateObj;
  try {
    dateObj = getDateFromUTC(date);
  } catch (error) {
    console.error(error);
    return '';
  }

  return isValidDate(dateObj) ? formatInTimeZone(dateObj, timeZone, format) : 'N/A';
}

export function getDateFromUTC(date: Date | string): Date | null {
  if (!date) return null;
  if (typeof date === 'string' && date.split('T').length > 1) {
    date = stripTime(date);
  }
  let zoned;
  try {
    zoned = fromZonedTime(date, 'UTC');
  } catch (error) {
    console.error(error);
    return null;
  }

  return isValidDate(zoned) ? zoned : null;
}

function stripTime(date: Date | string): Date | string {
  if (typeof date === 'string' && date.split('T').length > 1) {
    return `${date.split('T')[0]} ${date.split('T')[1].replace('Z', '')}`;
  }
  return date;
}
