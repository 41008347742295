import { GLOBAL_TIMEOUTS, CUSTOM_CLAIMS as globalClaims } from '@diagrid/cloud-ui-shared/utils';
import { authz } from '@diagrid/shared-js';
import { invert } from 'lodash';
import { PATH_API } from 'src/routes/paths';

export const OCI_REGISTRY = {
  dev: 'oci://public.ecr.aws/diagrid-dev/conductor-charts/agent',
  staging: 'oci://public.ecr.aws/diagrid-staging/conductor-charts/agent',
  prod: 'oci://public.ecr.aws/diagrid-prod/conductor-charts/agent',
};

export const LIMIT_NAMES = {
  actors: 'number_of_actors',
  alerts: 'number_of_alerts',
  apikeys: 'number_of_apikeys',
  clusters: 'number_of_clusters',
  components: 'number_of_components',
  configurations: 'number_of_configurations',
  dataRetentionLogsHours: 'data_retention_logs_hours',
  dataRetentionMetricsHours: 'data_retention_metrics_hours',
  metricSeries: 'number_of_metric_series',
  nonProdClusters: 'number_of_non_prod_clusters',
  notifications: 'number_of_notifications',
  prodClusters: 'number_of_prod_clusters',
  resiliencies: 'number_of_resiliencies',
  subscriptions: 'number_of_subscriptions',
  users: 'number_of_users',
  workloads: 'number_of_workloads',
  ssoConnections: 'number_of_sso_connections',
};

export const FEATURES = {
  sso: 'sso',
  ossSupport: 'oss_support',
};

export const CLUSTER_TYPES = [
  {
    id: 'prod',
    label: 'Production',
  },
  {
    id: 'nonprod',
    label: 'Non Production',
  },
];

export const TIMEOUTS = {
  ...GLOBAL_TIMEOUTS,
  DEFAULT_CLUSTER_POLLING: GLOBAL_TIMEOUTS.DEFAULT_RESOURCE_POLLING,
};

export const DEFAULT_HEADER_HEIGHT = 72;

export const EXTENDED_TIMESPANS = {
  '30d': {
    ts: '30d',
    start: '720:h',
    step: '28m',
    label: '30 days',
  },
};

export type HealthStatus = (typeof STATUS)['HEALTH_UP' | 'HEALTH_DEGRADED' | 'HEALTH_DOWN' | 'HEALTH_UNKNOWN'];
export enum STATUS {
  HEALTH_UP = 'up',
  HEALTH_DEGRADED = 'degraded',
  HEALTH_DOWN = 'down',
  HEALTH_UNKNOWN = 'unknown',
}

export const ANNOTATIONS = {
  daprAppId: 'dapr.io/app-id',
  daprAppPort: 'dapr.io/app-port',
};

export const DIAGRID_ROLE_DEFINITIONS = authz.constants.conductorRoles;

export const DIAGRID_ROLES = {
  admin: {
    id: authz.constants.defaultRoles.admin,
    label: 'Admin',
  },
  editor: {
    id: authz.constants.defaultRoles.editor,
    label: 'Editor',
  },
  viewer: {
    id: authz.constants.defaultRoles.viewer,
    label: 'Viewer',
  },
};

export const DIAGRID_ROLES_ORG_ADMIN = authz.constants.defaultRoles.orgAdmin;

export const API_REQUESTS = {
  orgIdHeader: 'x-diagrid-orgid',
};

export const CUSTOM_CLAIMS = {
  ...globalClaims,
};

// map of helm values overlays, contains objects that are applied to the default helm values when a kubernetes distro is selected
// the key of this map, must match with the value of the kubernetesDistro property
export const kubernetesDistrosHelmArgsOverlay = {
  openshift: {
    dapr_placement: {
      cluster: {
        forceInMemoryLog: true,
      },
    },
  },
};

export const eventKindToType = {
  DaprLogEvent: 'logs',
  MetricsEvent: 'metrics',
  AdvisorEvent: 'advisor',
};
export const eventTypesFromKind = invert(eventKindToType);

export const SANDBOX_EMAIL = 'sandbox@diagrid.dev';

export const TYPE_REF = {
  account: {
    tagType: 'accounts',
    url: `${PATH_API.conductorV1Beta1}/accounts/:id`,
  },
  accounts: {
    tagType: 'accounts',
    url: `${PATH_API.conductorV1Beta1}/accounts`,
  },
  actors: {
    tagType: 'actors',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/actors`,
  },
  advisorAlert: {
    tagType: 'alerts',
    url: `${PATH_API.conductorV1Beta1}/configs/alerts/advisor/:id`,
  },
  advisorAlerts: {
    tagType: 'alerts',
    url: `${PATH_API.conductorV1Beta1}/configs/alerts/advisor`,
  },
  advisorAlertsTemplates: {
    tagType: 'alerts-templates',
    url: `${PATH_API.conductorV1Beta1}/templates/configs/alerts/advisor?target_type=:targetType&scoped_resource_id=:scopedResourceId`,
  },
  advisorNotification: {
    tagType: 'notifications',
    url: `${PATH_API.conductorV1Beta1}/configs/notifications/advisor/:id`,
  },
  advisorNotifications: {
    tagType: 'notifications',
    url: `${PATH_API.conductorV1Beta1}/configs/notifications/advisor`,
  },
  advisorNotificationsTemplates: {
    tagType: 'notifications-templates',
    url: `${PATH_API.conductorV1Beta1}/templates/configs/notifications/advisor`,
  },
  advisors: {
    tagType: 'advisors',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/advisors`,
  },
  advisoryreport: {
    tagType: 'cluster-advisory-report',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/advisoryreport`,
  },
  alerts: {
    tagType: 'alerts',
    url: `${PATH_API.conductorV1Beta1}/configs/alerts`,
  },
  apikeys: {
    tagType: 'apiKeys',
    url: `${PATH_API.conductorV1Beta1}/apikeys`,
  },
  appActors: {
    tagType: 'app-actors',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/apps/:appId/actors`,
  },
  appComponents: {
    tagType: 'cluster-app-components',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/apps/:appId/components`,
  },
  appGraph: {
    tagType: 'cluster-app-graph',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/appgraph/:appId`,
  },
  appMetrics: {
    tagType: 'cluster-app-metrics',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/apps/:appId/metrics`,
  },
  appsGraph: {
    tagType: 'cluster-apps-graph',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/appgraph`,
  },
  appSubscriptions: {
    tagType: 'cluster-app-subscriptions',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/apps/:appId/subscriptions`,
  },
  auditLogs: {
    tagType: 'audit-logs',
    url: `${PATH_API.conductorV1Beta1}/audit`,
  },
  auditLogsFilters: {
    tagType: 'audit-logs-filters',
    url: `${PATH_API.conductorV1Beta1}/filters/audit`,
  },
  autoFixAdvisor: {
    tagType: 'advisors-fix',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/advisors/fix/:advisorName`,
  },
  cluster: {
    tagType: 'cluster',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id`,
  },
  clusterApp: {
    tagType: 'cluster-app',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/apps/:appId`,
  },
  clusterApps: {
    tagType: 'cluster-apps',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/apps`,
  },
  clusterAppsDaprInfo: {
    tagType: 'cluster-apps-dapr-info',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/apps/daprinfo`,
  },
  clusterForm: {
    // TODO: Possibly add pluralize but preferred would be fixing it to be consistent on the backend with clusterForms
    tagType: 'cluster-forms',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/forms`,
  },
  clusterFormLimited: {
    tagType: 'cluster-forms-limited',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/forms?skipExtraData=true`,
  },
  clusterForms: {
    tagType: 'cluster-forms',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/forms`,
  },
  clusterFormsPrevious: {
    tagType: 'previous-cluster-forms',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/forms/previous`,
  },
  clusterHealth: {
    tagType: 'cluster-health',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/health`,
  },
  clusterMetrics: {
    tagType: 'cluster-metrics',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/metrics`,
  },
  clusters: {
    tagType: 'clusters',
    url: `${PATH_API.conductorV1Beta1}/clusters`,
  },
  clustersForAlertOptions: {
    tagType: 'clusters-for-alert-options',
    url: `${PATH_API.conductorV1Beta1}/configs/alerts/clusters?type=:type`,
  },
  clusterStatus: {
    tagType: 'cluster-status',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/status`,
  },
  clusterStatusLogs: {
    tagType: 'cluster-logs',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/status/logs`,
  },
  component: {
    tagType: 'cluster-component',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/components/:componentId`,
  },
  components: {
    tagType: 'cluster-component',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/components`,
  },
  daprForm: {
    tagType: 'cluster-forms-dapr',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/forms/dapr`,
  },
  daprFormLimited: {
    tagType: 'cluster-forms-dapr-limited',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/forms/dapr?source=:source&skipExtraData=:skipExtraData`,
  },
  daprFormPrevious: {
    tagType: 'cluster-forms-dapr-previous',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/forms/dapr/previous`,
  },
  daprFormPreviousLimited: {
    tagType: 'cluster-forms-dapr-previous',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/forms/dapr/previous?source=:source&skipExtraData=:skipExtraData`,
  },
  diagnostics: {
    tagType: 'diagnostics',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/diagnostics`,
  },
  diagridNotifications: {
    tagType: 'diagridnotifications',
    url: `${PATH_API.conductorV1Beta1}/diagridnotifications`,
  },
  diagridNotificationsFilters: {
    tagType: 'diagridnotifications',
    url: `${PATH_API.conductorV1Beta1}/filters/diagridnotifications`,
  },
  dismissedAdvisor: {
    tagType: 'advisor-dismissed',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/advisors/dismissed/:advisorName`,
  },
  dismissedAdvisors: {
    tagType: 'advisors-dismissed',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/advisors/dismissed`,
  },
  logsAlert: {
    tagType: 'alerts',
    url: `${PATH_API.conductorV1Beta1}/configs/alerts/logs/:id`,
  },
  logsAlerts: {
    tagType: 'alerts',
    url: `${PATH_API.conductorV1Beta1}/configs/alerts/logs`,
  },
  logsAlertsTemplates: {
    tagType: 'alerts-templates',
    url: `${PATH_API.conductorV1Beta1}/templates/configs/alerts/logs?target_type=:targetType&scoped_resource_id=:scopedResourceId`,
  },
  logsNotification: {
    tagType: 'notifications',
    url: `${PATH_API.conductorV1Beta1}/configs/notifications/logs/:id`,
  },
  logsNotifications: {
    tagType: 'notifications',
    url: `${PATH_API.conductorV1Beta1}/configs/notifications/logs`,
  },
  logsNotificationsTemplates: {
    tagType: 'notifications-templates',
    url: `${PATH_API.conductorV1Beta1}/templates/configs/notifications/logs`,
  },
  meta: {
    tagType: 'meta',
    url: `${PATH_API.conductorV1Beta1}/meta`,
  },
  metadataschemas: {
    tagType: 'metadataschemas',
    url: `${PATH_API.conductorV1Beta1}/metadata/components`,
  },
  metricsAlert: {
    tagType: 'alerts',
    url: `${PATH_API.conductorV1Beta1}/configs/alerts/metrics/:id`,
  },
  metricsAlerts: {
    tagType: 'alerts',
    url: `${PATH_API.conductorV1Beta1}/configs/alerts/metrics`,
  },
  metricsAlertsTemplates: {
    tagType: 'alerts-templates',
    url: `${PATH_API.conductorV1Beta1}/templates/configs/alerts/metrics?target_type=:targetType&scoped_resource_id=:scopedResourceId`,
  },
  metricsNotification: {
    tagType: 'notifications',
    url: `${PATH_API.conductorV1Beta1}/configs/notifications/metrics/:id`,
  },
  metricsNotifications: {
    tagType: 'notifications',
    url: `${PATH_API.conductorV1Beta1}/configs/notifications/metrics`,
  },
  metricsNotificationsTemplates: {
    tagType: 'notifications-templates',
    url: `${PATH_API.conductorV1Beta1}/templates/configs/notifications/metrics`,
  },
  notifications: {
    tagType: 'notifications',
    url: `${PATH_API.conductorV1Beta1}/configs/notifications`,
  },
  organizations: {
    tagType: 'organizations',
    url: `${PATH_API.conductorV1Beta1}/organizations`,
  },
  organizationSSOConnection: {
    tagType: 'ssoconnections',
    url: `${PATH_API.conductorV1Beta1}/organizations/ssoconnections/:id`,
  },
  organizationSSOConnections: {
    tagType: 'ssoconnections',
    url: `${PATH_API.conductorV1Beta1}/organizations/ssoconnections`,
  },
  resetPassword: {
    tagType: 'accounts',
    url: `${PATH_API.conductorV1Beta1}/accounts/:id/reset_password`,
  },
  rollouts: {
    tagType: 'rollouts',
    url: `${PATH_API.conductorV1Beta1}/clusters/:id/rollouts`,
  },
  tours: {
    tagType: 'tours',
    url: `${PATH_API.conductorV1Beta1}/user/tours`,
  },
  userOrganizations: {
    tagType: 'organizations',
    url: `${PATH_API.conductorV1Beta1}/user/organizations`,
  },
  userSelf: {
    tagType: 'users',
    url: `${PATH_API.conductorV1Beta1}/user`,
  },
  workload: {
    tagType: 'workloads-by-clusterid',
    url: `${PATH_API.conductorV1Beta1}/workloads/:id/apps/:appId`,
  },
  workloads: {
    tagType: 'workloads-by-clusterid',
    url: `${PATH_API.conductorV1Beta1}/workloads/:id`,
  },
  workloadsForAlertOptions: {
    tagType: 'workloads-for-alert-options',
    url: `${PATH_API.conductorV1Beta1}/configs/alerts/clusters/:id/apps?type=:type`,
  },
};
