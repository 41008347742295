import { datadogRum } from '@datadog/browser-rum';
import { DateTimeRangePickerValues, generateStepDateRangeStep } from '@diagrid/cloud-ui-shared';
import { useLocalStorage } from '@diagrid/cloud-ui-shared/hooks';
import { DATAGRID_GLOBALS, GLOBAL_AVAILABLE_TIMESPANS } from '@diagrid/cloud-ui-shared/utils';
import { isEmpty, values } from 'lodash';
import { PropsWithChildren, createContext, useEffect } from 'react';
import { DEFAULT_HEADER_HEIGHT, EXTENDED_TIMESPANS } from 'src/utils/constants';
import { colorPresets, defaultPreset, defaultSettings, getColorPresets } from '../theme';
import { isProduction } from '../utils/get-runtime-env';

const initialState = {
  ...defaultSettings,
  headerHeight: DEFAULT_HEADER_HEIGHT,
  // Mode
  onToggleMode: () => {},
  onChangeMode: () => {},

  // Direction
  onToggleDirection: () => {},
  onChangeDirection: () => {},
  onChangeDirectionByLang: (_) => {},

  // Layout
  onToggleLayout: () => {},
  onChangeLayout: () => {},
  onChangeHeaderHeight: (_) => {},

  // Contrast
  onToggleContrast: () => {},
  onChangeContrast: () => {},

  // Color
  onChangeColor: () => {},
  setColor: defaultPreset,
  colorOption: [],

  // Stretch
  onToggleStretch: () => {},

  // Reset
  onResetSetting: () => {},

  // User
  onPageSizeChange: (_) => {},
  // user charts
  timespan: GLOBAL_AVAILABLE_TIMESPANS['30m'],
  timeRange: {
    relativeTime: GLOBAL_AVAILABLE_TIMESPANS['30m'].ts,
  },
  hiddenCharts: [],
  setTimespan: (_) => {},
  hideChart: (_) => {},
  showChart: (_) => {},
};

const SettingsContext = createContext(initialState);

function SettingsProvider({ children }: PropsWithChildren) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    themeLayout: initialState.themeLayout,
    themeStretch: initialState.themeStretch,
    themeContrast: initialState.themeContrast,
    themeDirection: initialState.themeDirection,
    themeColorPresets: initialState.themeColorPresets,
    userTableRowCount: initialState.userTableRowCount,
    timespan: initialState.timespan,
    timeRange: initialState.timeRange,
    hiddenCharts: initialState.hiddenCharts,
  });

  const isArabic = localStorage.getItem('i18nextLng') === 'ar';

  useEffect(() => {
    if (isEmpty(settings.timespan)) {
      setSettings({
        ...settings,
        timespan: GLOBAL_AVAILABLE_TIMESPANS['30m'],
      });
    }
  }, [setSettings, settings]);

  useEffect(() => {
    if (isArabic) {
      onChangeDirectionByLang('ar');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArabic]);

  useEffect(() => {
    if (isProduction()) {
      try {
        datadogRum.setUserProperty('theme', {
          mode: settings.themeMode,
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, [settings.themeMode]);

  // Mode

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
      themeColorPresets: settings.themeMode === 'light' ? 'blueLightTheme' : 'blue',
    });
  };

  const onChangeMode = (event) => {
    setSettings({
      ...settings,
      themeMode: event.target.value,
      themeColorPresets: event.target.value === 'light' ? 'blueLightTheme' : 'blue',
    });
  };

  // Direction

  const onToggleDirection = () => {
    setSettings({
      ...settings,
      themeDirection: settings.themeDirection === 'rtl' ? 'ltr' : 'rtl',
    });
  };

  const onChangeDirection = (event) => {
    setSettings({
      ...settings,
      themeDirection: event.target.value,
    });
  };

  const onChangeDirectionByLang = (lang) => {
    setSettings({
      ...settings,
      themeDirection: lang === 'ar' ? 'rtl' : 'ltr',
    });
  };

  // Layout

  const onToggleLayout = () => {
    setSettings({
      ...settings,
      themeLayout: settings.themeLayout === 'vertical' ? 'horizontal' : 'vertical',
    });
  };

  const onChangeLayout = (event) => {
    setSettings({
      ...settings,
      themeLayout: event.target.value,
    });
  };

  const onChangeHeaderHeight = (height) => {
    setSettings({
      ...settings,
      headerHeight: height,
    });
  };
  // Contrast

  const onToggleContrast = () => {
    setSettings({
      ...settings,
      themeContrast: settings.themeContrast === 'default' ? 'bold' : 'default',
    });
  };

  const onChangeContrast = (event) => {
    setSettings({
      ...settings,
      themeContrast: event.target.value,
    });
  };

  // Color

  const onChangeColor = (event) => {
    setSettings({
      ...settings,
      themeColorPresets: event.target.value,
    });
  };

  // Stretch

  const onToggleStretch = () => {
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch,
    });
  };

  // User

  // charts
  const handleUpdateTimespan = (value: DateTimeRangePickerValues | null) => {
    const isEmpty = !value || (value && !value.relativeTime && !value.startTime && !value.endTime);
    if (!value || isEmpty) {
      setSettings({
        ...settings,
        timespan: initialState.timespan,
        timeRange: initialState.timeRange,
      });
      return;
    }

    if (value.relativeTime) {
      setSettings({
        ...settings,
        timespan: GLOBAL_AVAILABLE_TIMESPANS[value.relativeTime] ?? EXTENDED_TIMESPANS[value.relativeTime],
        timeRange: value,
      });
    }
    if (value.startTime && value.endTime) {
      setSettings({
        ...settings,
        timespan: generateStepDateRangeStep({ start: value.startTime, end: value.endTime, extendedTimespans: EXTENDED_TIMESPANS }),
        timeRange: value,
      });
    }
  };

  const onPageSizeChange = (neu: number) => {
    // check the neu value is valid
    if (DATAGRID_GLOBALS.rowsPerPage.includes(neu)) {
      setSettings({
        ...settings,
        userTableRowCount: neu,
      });
    } else {
      console.warn(`Invalid page size: ${neu}`);
      // if the value is not valid, set it to the default value
      setSettings({
        ...settings,
        userTableRowCount: DATAGRID_GLOBALS.defaultPagination.pageSize,
      });
    }
  };

  const onSetHiddenCharts = (hiddenChart: string) => {
    setSettings({
      ...settings,
      hiddenCharts: [...(settings?.hiddenCharts ?? []), hiddenChart],
    });
  };

  const onRemoveHiddenCharts = (hiddenChart: string) => {
    setSettings({
      ...settings,
      hiddenCharts: settings.hiddenCharts.filter((chart) => chart !== hiddenChart),
    });
  };

  // Reset

  const onResetSetting = () => {
    setSettings({
      themeMode: initialState.themeMode,
      themeLayout: initialState.themeLayout,
      themeStretch: initialState.themeStretch,
      themeContrast: initialState.themeContrast,
      themeDirection: initialState.themeDirection,
      themeColorPresets: initialState.themeColorPresets,
      timespan: initialState.timespan,
      hiddenCharts: initialState.hiddenCharts,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,

        // Mode
        onToggleMode,
        onChangeMode,

        // Direction
        onToggleDirection,
        onChangeDirection,
        onChangeDirectionByLang,

        // Layout
        onToggleLayout,
        onChangeLayout,
        onChangeHeaderHeight,

        // Contrast
        onChangeContrast,
        onToggleContrast,

        // Stretch
        onToggleStretch,

        // Color
        onChangeColor,
        setColor: getColorPresets(settings.themeColorPresets),
        colorOption: values(colorPresets).map((color) => ({
          name: color.name,
          value: color.main,
        })),

        // User
        onPageSizeChange,
        setTimespan: handleUpdateTimespan,
        hideChart: onSetHiddenCharts,
        showChart: onRemoveHiddenCharts,

        // Reset
        onResetSetting,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsContext, SettingsProvider };
