import { withAuthenticationRequired } from '@auth0/auth0-react';
import reactLazyWithRetry from '@fatso83/retry-dynamic-import/react-lazy';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { LoadingScreen } from 'src/components/LoadingScreen';
import { PATH_AFTER_LOGIN } from 'src/config';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import DashboardLayout from 'src/layouts/dashboard';
import { NewUserIntake } from 'src/pages/NewUserIntake';
import { SignUpSuccess } from 'src/pages/SignUpSuccess';
import { VerifyEmail } from 'src/pages/VerifyEmail';
import Login from 'src/pages/auth/Login';
import GenericError from '../pages/GenericError';
import { PATH_DASHBOARD } from './paths';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

const ProtectedLayout = withAuthenticationRequired(DashboardLayout, {
  loginOptions: {
    openUrl(url) {
      const query = window.location.search;
      if (query.includes('error=access_denied')) {
        let reason = 'Access denied';
        if (query.includes('error_description=')) {
          const searchParams = new URLSearchParams(query);
          reason = searchParams.get('error_description') ?? reason;
        }

        window.location.href = `/error?error_description=${encodeURIComponent(reason)}`;
      } else {
        window.location.replace(url);
      }
    },
  },
  onRedirecting: () => <LoadingScreen />,
});

export default function Router() {
  return useRoutes([
    {
      path: 'login',
      element: (
        <ErrorBoundary FallbackComponent={GenericError}>
          <Login />
        </ErrorBoundary>
      ),
    },
    // Dashboard Routes
    {
      path: '/',
      element: (
        <ErrorBoundary FallbackComponent={GenericError}>
          <ProtectedLayout />
        </ErrorBoundary>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'clusters',
          children: [
            { element: <ClustersList />, index: true },
            { path: 'create', element: <ClusterNew /> },
            {
              // Tabbed page - wildcard parent, pass tab to same component as route param, in component pass route param to useTabs
              path: ':id/detail/*',
              children: [
                { path: ':tab', element: <ClusterDetail /> },
                { path: ':tab/:resourceId', element: <ClusterDetail /> },
                { path: 'components/:componentId/detail', element: <ComponentDetail /> },
              ],
            },
            { path: ':id/edit', element: <ClusterEdit /> },
            { path: ':id/edit/dapr', element: <ControlPlaneUpdate /> },
            { path: ':id/revert/dapr', element: <ControlPlaneRevert /> },
            { path: ':id/health', element: <ClusterHealth /> },
            { path: ':id/logs', element: <ClusterLogs /> },
            {
              path: ':id/app/:appId/detail/*',
              children: [
                { path: ':tab', element: <ClusterAppDetail /> },
                { path: ':tab/:resourceId', element: <ClusterAppDetail /> },
                { path: ':tab/:configurationId', element: <ClusterAppDetail /> },
                { path: 'components/:componentId/detail', element: <ComponentDetail /> },
              ],
            },
            { path: ':id/app/:appId/detail/pods', element: <ClusterAppPods /> },
          ],
        },
        {
          path: 'users',
          children: [
            { element: <UserList />, index: true },
            { path: 'create', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
            {
              path: 'settings/*',
              children: [{ path: ':tab', element: <UserAccount /> }],
            },
          ],
        },
        {
          path: 'organization',
          children: [
            { element: <Organization />, index: true },
            { path: ':tab', element: <Organization /> },
            { path: 'edit', element: <OrgEdit /> },
            { path: 'ssoconnections', element: <NewSSOConnection /> },
            { path: 'ssoconnections/:id', element: <SSOConnectionDetails /> },
            { path: 'ssoconnections/:id/edit', element: <NewSSOConnection /> },
          ],
        },
        {
          path: 'api-keys',
          children: [
            { element: <ApiKeys />, index: true },
            { path: 'create', element: <CreateApiKey /> },
          ],
        },
        {
          path: PATH_DASHBOARD.notifications.management,
          children: [
            {
              element: <NotificationsManagement />,
              children: [
                { element: <NotificationsManagement />, index: true },
                {
                  path: ':tab',
                  element: <NotificationsManagement />,
                },
              ],
            },
            {
              path: 'notification',
              children: [
                { path: 'create/:type', element: <NotificationsCreate /> },
                { path: 'clone/:type/:id', element: <NotificationsCreate /> },
                { path: 'edit/:type/:id', element: <NotificationsCreate /> },
                { path: 'detail/:type/:id', element: <NotificationsDetail /> },
              ],
            },
            {
              path: 'alert',
              children: [
                { path: 'create/', element: <AlertsCreate /> },
                { path: 'create/:type', element: <AlertsCreate /> },
                { path: 'create/:type/:clusterId/:appId', element: <AlertsCreate /> },
                { path: 'clone/:type/:id', element: <AlertsCreate /> },
                { path: 'edit/:type/:id', element: <AlertsCreate /> },
                { path: 'detail/:type/:id', element: <AlertDetail /> },
              ],
            },
          ],
        },
        { path: 'build-info', element: <BuildInfo /> },
        {
          path: '/component-builder',
          element: <ComponentConfigurator />,
        },
        {
          path: '/resiliency-builder',
          element: <ResiliencyBuilderPage />,
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: (
        <ErrorBoundary FallbackComponent={GenericError}>
          <LogoOnlyLayout />
        </ErrorBoundary>
      ),
      children: [
        { path: 'new-user-survey', element: <NewUserIntake /> },
        { path: 'verify-email', element: <VerifyEmail /> },
        { path: 'sign-up-success', element: <SignUpSuccess /> },
        { path: 'error', element: <GenericError /> },
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'no-product-access', element: <NoProductAccess /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Dashboard
const ClustersList = Loadable(reactLazyWithRetry(() => import('src/pages/clusters/ClustersList')));
const ClusterNew = Loadable(reactLazyWithRetry(() => import('src/pages/clusters/New')));
const ClusterEdit = Loadable(reactLazyWithRetry(() => import('src/pages/clusters/Edit')));
const ControlPlaneUpdate = Loadable(reactLazyWithRetry(() => import('src/pages/clusters/ControlPlaneUpdate')));
const ControlPlaneRevert = Loadable(reactLazyWithRetry(() => import('src/pages/clusters/ControlPlaneRevert')));
const ClusterDetail = Loadable(reactLazyWithRetry(() => import('src/pages/clusters/Detail')));
const ClusterHealth = Loadable(reactLazyWithRetry(() => import('src/pages/clusters/Health')));
const ClusterLogs = Loadable(reactLazyWithRetry(() => import('src/pages/clusters/Logs')));
const ClusterAppDetail = Loadable(reactLazyWithRetry(() => import('src/pages/clusters/apps/apps-details')));
const ClusterAppPods = Loadable(reactLazyWithRetry(() => import('src/pages/clusters/apps/pods/ClusterAppPods')));
const UserList = Loadable(reactLazyWithRetry(() => import('src/pages/users/UserList')));
const UserAccount = Loadable(reactLazyWithRetry(() => import('src/pages/users/UserAccount')));
const UserCreate = Loadable(reactLazyWithRetry(() => import('src/pages/users/UserCreate')));
const NotificationsManagement = Loadable(reactLazyWithRetry(() => import('src/pages/notifications/Management')));
const NotificationsCreate = Loadable(reactLazyWithRetry(() => import('src/pages/notifications/New')));
const AlertsCreate = Loadable(reactLazyWithRetry(() => import('src/pages/alerts/New')));
const NotificationsDetail = Loadable(reactLazyWithRetry(() => import('src/pages/notifications/Detail')));
const AlertDetail = Loadable(reactLazyWithRetry(() => import('src/pages/alerts/Detail')));
const ApiKeys = Loadable(reactLazyWithRetry(() => import('src/pages/api-keys/')));
const CreateApiKey = Loadable(reactLazyWithRetry(() => import('src/pages/api-keys/CreateApiKey')));
const ComponentConfigurator = Loadable(reactLazyWithRetry(() => import('src/pages/configurator')));
const ResiliencyBuilderPage = Loadable(reactLazyWithRetry(() => import('src/pages/resiliency-builder')));
const ComponentDetail = Loadable(reactLazyWithRetry(() => import('src/pages/components/Detail')));
// const DiagridNotifications = Loadable(reactLazyWithRetry(() => import('src/pages/diagridnotifications')));
const Organization = Loadable(reactLazyWithRetry(() => import('src/pages/organization')));
const OrgEdit = Loadable(reactLazyWithRetry(() => import('src/pages/organization/New'))); // only one org so far, new is edit/new
const NewSSOConnection = Loadable(reactLazyWithRetry(() => import('src/pages/organization/NewSSOConnection')));
const SSOConnectionDetails = Loadable(reactLazyWithRetry(() => import('src/pages/organization/SSOConnectionDetails')));
// Main
const ComingSoon = Loadable(reactLazyWithRetry(() => import('src/pages/ComingSoon')));
const NoProductAccess = Loadable(reactLazyWithRetry(() => import('src/pages/NoProductAccess')));
const BuildInfo = Loadable(reactLazyWithRetry(() => import('src/pages/BuildInfo')));
const Maintenance = Loadable(reactLazyWithRetry(() => import('src/pages/Maintenance')));
const Page500 = Loadable(reactLazyWithRetry(() => import('src/pages/Page500')));
const Page403 = Loadable(reactLazyWithRetry(() => import('../pages/Page403')));
const Page404 = Loadable(reactLazyWithRetry(() => import('../pages/Page404')));
